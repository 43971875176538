/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2022-09-26 14:47:17
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-09-26 15:14:38
 */
import config from './config/play'
import axios from '@/utils/request'
export const getCourseList = params => axios.post(config.getCourseList, params)
export const getVideoUrl = params => axios.post(config.getVideoUrl, params)
