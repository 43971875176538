<template>
  <div class="playVideo">
    <div class="container">
      <div class="nav_path">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: `/class/classDetail/${classId}` }"
            >班级详情</el-breadcrumb-item
          >
          <el-breadcrumb-item>课程播放</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="video_box">
        <div class="main">
          <div class="title">
            {{ courseTitle }}
          </div>
          <div id="player" class="prism-player"></div>
        </div>
        <div>
          <div class="courseList_nav">
            <div class="courseName">{{ courseName }}</div>
          </div>
          <div class="courseList">
            <div
              v-for="(item, index) in courseList"
              :key="item.sectionId"
              class="course_box"
              @click="
                getVideoPlayUrl(classId, courseId, item.sectionId, item.sectionName, item.duration)
              "
            >
              <p :class="[courseTitle == item.sectionName ? 'course_name_change' : 'course_name']">
                <img v-show="item.studyStatus == 0" src="@/assets/play/unsocked.png" alt="" /><img
                  v-show="item.studyStatus == 1"
                  src="@/assets/play/icon-looking.png"
                  alt=""
                /><img v-show="item.studyStatus == 2" src="@/assets/play/icon-finish.png" alt="" />
                第<span v-show="index < 9">0</span>{{ index + 1 }}节
                {{ item.sectionName }}
              </p>
              <p class="course_time">时长：{{ item.durationStr }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="courseNav">
        <el-tabs v-model="activeName">
          <el-tab-pane label="课程目录" name="first">
            <div
              v-for="(item, index) in courseList"
              :key="index"
              class="class-item"
              @click="
                getVideoPlayUrl(classId, courseId, item.sectionId, item.sectionName, item.duration)
              "
            >
              <div class="class-item-a">
                <div
                  :class="[
                    courseTitle == item.sectionName ? 'class-item-a-a_change' : 'class-item-a-a',
                  ]"
                >
                  <img v-show="item.studyStatus == 2" src="@/assets/play/icon-finish.png" alt="" />
                  <img v-show="item.studyStatus == 1" src="@/assets/play/icon-looking.png" alt="" />
                  <img v-show="item.studyStatus == 0" src="@/assets/play/unsocked.png" alt="" />
                  第<span v-show="index < 9">0</span>{{ index + 1 }}节
                  {{ item.sectionName }}
                </div>
                <div class="class-item-a-b">
                  <span class="class-item-a-b-a">时长：{{ item.durationStr }}</span>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="课件资料" name="second"> </el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { getCourseList, getVideoUrl } from '@/api/play.js'
export default {
  name: 'ClassPlay',
  data() {
    return {
      courseName: this.$route.query.courseName,
      classId: this.$route.query.classId,
      courseId: this.$route.query.courseId,
      sectionId: this.$route.query.sectionId,
      courseTitle: this.$route.query.sectionName,
      duration: this.$route.query.duration,
      currentDuration: '',
      source: '',
      cover: '',
      finish: '',
      courseList: '',
      player: '',
      activeName: 'first',
      playStartTime: '',
      msg: '',
      needPunch: '',
      sliderIndex: '',
      timeFlag: 0,
      needSinglePoint: false,
      singlePointId: '',
      autoChecked: false,
      diong: false,
      count: '',
      percentage: 0,
      colors: [
        { color: '#ff7b33', percentage: 20 },
        { color: '#ff7b33', percentage: 40 },
        { color: '#ff7b33', percentage: 60 },
        { color: '#ff7b33', percentage: 80 },
        { color: '#ff7b33', percentage: 100 },
      ],
      uploadFlag: false,
    }
  },
  destroyed() {},
  created() {
    let IsAutoVideo = this.$route.query.IsAutoVideo
    if (IsAutoVideo) {
      this.autoChecked = true
    }
    //获取课程列表
    getCourseList({
      classId: this.classId,
      courseId: this.courseId,
    }).then(res => {
      if (res.msg == 'success') {
        this.courseList = res.data
        if (!this.sectionId) {
          this.sectionId = res.data[0].sectionId
          this.courseTitle = res.data[0].sectionName
        }
        console.log(this.sectionId)
        this.courseList.map((item, index) => {
          if (this.sectionId == item.sectionId) {
            this.index = index
            this.currentDuration = item.currentDuration
            getVideoUrl({
              classId: this.classId,
              sectionId: this.sectionId,
            }).then(res => {
              if (res.msg == 'success') {
                this.source = res.data.videoUrl
                this.cover = res.data.coverUrl
                this.goPlayer()
              } else {
                this.$message({
                  message: res.msg,
                  type: 'warning',
                })
              }
            })
          }
        })
      } else {
        this.$message({
          message: res.msg,
          type: 'warning',
        })
      }
    })
  },
  methods: {
    prev() {
      this.$router.push(`/class?classId=${this.classId}`)
    },
    goPlayer() {
      const _this = this
      // eslint-disable-next-line no-undef
      this.player = new Aliplayer(
        {
          id: 'player',
          source: this.source,
          width: '100%',
          height: '490px',
          cover: this.cover,
          autoplay: true,
          isLive: false,
          rePlay: false,
          playsinline: true,
          preload: true,
          controlBarVisibility: 'hover',
          useH5Prism: true,
          components: [
            {
              name: 'RateComponent',
              // eslint-disable-next-line no-undef
              type: AliPlayerComponent.RateComponent,
            },
          ],
          skinLayout: [
            {
              name: 'bigPlayButton',
              align: 'blabs',
              x: 30,
              y: 80,
            },
            {
              name: 'H5Loading',
              align: 'cc',
            },
            {
              name: 'errorDisplay',
              align: 'tlabs',
              x: 0,
              y: 0,
            },
            {
              name: 'infoDisplay',
            },
            {
              name: 'tooltip',
              align: 'blabs',
              x: 0,
              y: 56,
            },
            {
              name: 'thumbnail',
            },
            {
              name: 'controlBar',
              align: 'blabs',
              x: 0,
              y: 0,
              children: [
                {
                  name: 'progress',
                  align: 'blabs',
                  x: 0,
                  y: 44,
                },
                { name: 'playButton', align: 'tl', x: 15, y: 12 },
                { name: 'timeDisplay', align: 'tl', x: 10, y: 7 },
                { name: 'fullScreenButton', align: 'tr', x: 10, y: 12 },
                { name: 'volume', align: 'tr', x: 5, y: 10 },
              ],
            },
          ],
        },
        function(player) {
          player.on('ready', function() {
            if (_this.currentDuration == _this.duration) {
              _this.player.seek(0)
            } else {
              _this.player.seek(_this.currentDuration)
            }
          })
          player.on('play', function() {})
          player.on('pause', function() {})
          player.on('timeupdate', function() {})
          player.on('ended', function() {})
          player.on('showBar', () => {})
          player.on('hideBar', () => {})
          player.on('error', () => {})
        },
      )
    },
    //点击切换视频
    getVideoPlayUrl(classId, courseId, sectionId, sectionName, duration) {
      this.$router.push({
        path: `/class/play`,
        query: {
          courseName: this.courseName,
          classId: classId,
          courseId: courseId,
          sectionId: sectionId,
          sectionName: sectionName,
          duration: duration,
        },
      })
      location.reload()
    },
  },
}
</script>
<style scoped>
.playVideo {
  box-sizing: border-box;
  width: 100%;
  background-color: #f5f5f5;
  height: 100vh;
  overflow: auto;
}
.container {
  width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
}
.nav_path {
  margin-bottom: 20px;
}
.video_box {
  display: flex;
  width: 100%;
  height: 563px;
  background-color: #131313;
}
.course_wrap {
  display: block;
}
.course_wrap_change {
  display: none;
}

.title {
  height: 60px;
  line-height: 60px;
  background-color: #232323;
  font-size: 20px;
  color: #ffffff;
  padding-left: 30px;
  justify-content: space-between;
}
.study_btn {
  padding: 5px;
  margin-left: 11px;
  box-sizing: border-box;
  border: 1px solid #31f869;
  border-radius: 13px;
  color: #31f869;
  font-size: 14px;
}
.finish_btn {
  padding: 5px;
  margin-left: 11px;
  box-sizing: border-box;
  border: 1px solid #777777;
  border-radius: 13px;
  color: #777777;
  font-size: 14px;
}
.title_left {
  display: block;
  width: 16px;
  height: 28px;
  cursor: pointer;
}
.title_left_change {
  display: none;
  width: 16px;
  height: 28px;
  cursor: pointer;
}
.title_right {
  display: none;
  width: 16px;
  height: 28px;
  cursor: pointer;
}
.title_right_change {
  display: block;
  width: 16px;
  height: 28px;
  cursor: pointer;
}
.main {
  width: 870px;
  height: 560px;
}
.main_change {
  width: 100%;
  height: 560px;
}
.courseList_nav {
  width: 330px;
  color: #fff;
  height: 60px;
  background-color: #131313;
}
.courseName {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-left: auto;
  padding: 0 23px;
  background-color: #131313;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.courseList {
  box-sizing: border-box;
  width: 330px;
  height: 490px;
  padding: 0 20px;
  overflow-y: scroll;
}
.courseList::-webkit-scrollbar {
  width: 0px;
}
.course_box {
  cursor: pointer;
  padding: 20px 0;
  border-bottom: 1px solid #333333;
}
.course_name {
  display: flex;
  font-size: 14px;
  color: #c2c2c2;
  margin-bottom: 10px;
}
.course_name_change {
  display: flex;
  font-size: 14px;
  color: #ff7b33;
  margin-bottom: 6px;
  line-height: 15px;
}
.course_name_change img {
  display: block;
  width: 15px;
  margin: auto 15px auto 0;
  height: 15px;
}
.course_name img {
  display: block;
  width: 15px;
  margin: auto 15px auto 0;
  height: 15px;
}
.course_time {
  font-size: 14px;
  line-height: 14px;
  color: #777777;
  padding-left: 30px;
}
.courseNav {
  width: 870px;
  margin: 20px 0;
  background-color: #fff;
}
.courseNav >>> .el-tabs__nav {
  margin-left: 20px;
}
.courseNav >>> .el-tabs__item {
  padding: 0 40px;
  height: 60px;
  font-size: 18px;
  line-height: 60px;
}
.courseNav >>> .el-tabs__content {
  padding: 15px 20px;
}
.courseNav >>> .el-collapse-item__header {
  padding: 0 25px;
  height: 70px;
  line-height: 70px;
  background-color: #fff5f2;
  font-size: 18px;
  color: #3e3e3e;
}
.courseNav >>> .el-tabs__item.is-active {
  color: #ff7b33;
}
.courseNav >>> .el-tabs__active-bar {
  height: 4px;
  background-color: #ff7b33;
}
.courseNav >>> .el-tabs__item:hover {
  color: #ff7b33;
}
.class-item {
  display: flex;
  justify-content: space-between;
  height: 60px;
  cursor: pointer;
  padding-top: 13px;
  box-sizing: border-box;
}

.class-item:hover {
  background: #f8f8f8;
}
.class-item-b {
  width: 140px;
  font-size: 14px;
  color: #999999;
  text-align: center;
  line-height: 50px;
}
.class-item-a-a {
  font-size: 14px;
  color: #3e3e3e;
  line-height: 15px;
  display: flex;
  margin-bottom: 6px;
}
.class-item-a-a_change {
  font-size: 14px;
  color: #ff7b33;
  line-height: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
.class-item-a-b {
  font-size: 14px;
  color: #909399;
  line-height: 15px;
}
.class-item-a-a_change img {
  display: block;
  width: 15px;
  height: 15px;
  margin: 0 8px 0 0;
}
.class-item-a-a img {
  display: block;
  width: 15px;
  height: 15px;
  margin: 0 8px 0 0;
}
.class-item-a-b-a {
  margin: 0 25px;
}
.live-btn {
  width: 80px;
  height: 24px;
  line-height: 24px;
  margin-top: 5px;
  text-align: center;
  border: 1px solid #409eff;
  border-radius: 13px;
  color: #409eff;
}
.status-msg {
  margin-top: 5px;
}
.arr_box {
  width: 66px;
  height: 60px;
  padding: 20px 25px;
  background: #131313;
}
.cover_item {
  position: absolute;
  left: 0;
  bottom: 7%;
  width: 100%;
  height: 20px;
  z-index: 99999;
}
.cover_item1 {
  position: absolute;
  right: 8%;
  bottom: 1%;
  width: 50px;
  height: 50px;
  z-index: 99999;
}
.yzm_item {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99999;
}
.yzm {
  position: absolute;
  left: 15%;
  top: 20%;
  z-index: 99999;
}
.wrap {
  position: relative;
  width: 100%;
  height: 100%;
}
.backbtn {
  font-size: 16px;
  float: right;
  cursor: pointer;
  margin-right: 20px;
}
.autoVideo {
  position: absolute;
  z-index: 10;
  bottom: 10px;
  left: 200px;
}
.autoVideo >>> .el-checkbox__inner {
  border-radius: 50%;
  border: 1px solid #ffffff;
  background: rgba(75, 75, 75, 0.15);
}
.autoVideo >>> .el-checkbox__label {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 0px;
  color: #ffffff;
}
.autoVideo >>> .el-checkbox__inner::after {
  width: 6px;
  height: 6px;
  background: #ffffff;
  border-radius: 50%;
  left: 3px;
  top: 3px;
  border: none;
}
.diong {
  width: 870px;
  height: 490px;
  position: absolute;
  top: 113px;
  z-index: 99999999;
  background-color: #000;
  opacity: 0.8;
}
.diong >>> .el-progress-circle {
  width: 166px !important;
  height: 166px !important;
  position: absolute;
  top: 100px;
  left: 341px;
  z-index: 999999999;
}
.time_text {
  font-size: 52px;
  color: #ffffff;
  position: absolute;
  top: 170px;
  left: 406px;
  z-index: 999999999;
}
</style>
