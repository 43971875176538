/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2022-09-26 14:47:21
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-10-10 16:55:49
 */
export default {
  getCourseList: '/course/manage/homepage/course/detail/no/class',
  getVideoUrl: '/course/manage/homepage/class/course/section/no/class/watch/info',
}
